import {
    ErrorTemplateDeletion,
    InfobipDuplicatedCreationResponse,
    WhatsAppTemplate,
    WhatsAppTemplateCategory,
    WhatsAppTemplateLanguage,
    WhatsAppTemplateStatus
} from '@infobip/api'
import { Language } from '../../../translation/languages'
import { InteractionType } from '../../../infobip'
import { TemplateMessage, TemplateMessageDefaultValue } from '../../../internal-communication'
import { BrokerEnum } from '../../../partner-credential'
import { GroupAndTypeInfo } from './commons'
import { Meta } from './paginate'
import { GupshupDuplicatedCreationResponse } from './gupshup-template'

export type TemplatesChannelsRequest = { group: string; withName: boolean }

export type TemplatesChannelPermissions = { allowCreation: boolean; allowEdition: boolean; allowDeletion: boolean }

export type TemplatesChannelPermission = { permissions: TemplatesChannelPermissions }

export type TemplatesChannelsResponse = { phone: string; name: string; broker: BrokerEnum } & TemplatesChannelPermission

export type TemplateMessageResponse = TemplateMessage & {
    status: TemplateStatusEnum
    fields: TemplatesFields[]
    strButtons?: string
    segmentation?: number
}

export type ChannelOptionsRequest = {
    group: string
    channel: string
    language: Language
    ignoreCache: boolean
}

export type ChannelOptionsResponse = {
    creationOptions: TemplateOptions
    fields: TemplatesFields[]
    groupsAndTypes: GroupAndTypeInfo
}

export type ChannelTemplatesRequest = {
    group: string
    channel: string
    language: Language
    options: ChannelTemplatesRequestOptions
}

export type ChannelTemplatesResponse = {
    templates: TemplateMessageResponse[]
    meta: Meta
}

export type TemplateOptions = {
    types: TemplatesOptionsTypes[]
    interactionTypes: TemplatesOptionsInteractionTypes[]
    categories: TemplatesOptionsCategories[]
    languages: TemplatesOptionsLanguages[]
    defaultLanguage?: TemplatesOptionsLanguages
}

export type TemplatesOptionsTypesId = 'DEFAULT' | 'INTERACTIVE'

export type TemplatesOptionsTypes = { id: TemplatesOptionsTypesId; label: string }

export type TemplatesOptionsInteractionTypes = {
    id: InteractionType
    label: string
    buttons: TemplatesOptionsInteractionTypesButtons[]
}

export type TemplatesOptionsInteractionTypesButtons = { id: string; label: string }

export type TemplatesOptionsCategories = { id: WhatsAppTemplateCategory; label: string; description: string }

export type TemplatesOptionsLanguages = { id: WhatsAppTemplateLanguage; label: string }

export type TemplatesFields = { id?: string; key: string; label: string; value?: string }

export enum TemplateStatusEnum {
    AVAILABLE = 'AVAILABLE',
    INACTIVE = 'INACTIVE',
    PENDING_CONFIGURATION = 'PENDING_CONFIGURATION',
    PENDING_DELETION = 'PENDING_DELETION',
    WAITING_APPROVAL = 'WAITING_APPROVAL',
    REJECTED = 'REJECTED'
}

export type TemplateInteractiveButtonsRequest = { group: string; channel: string }

export type TemplateInteractiveButtonsResponse = {
    INTERACTIVE_BUTTON_POSITIVE_REPLY: string
    INTERACTIVE_BUTTON_NEGATIVE_REPLY: string
    INTERACTIVE_BUTTON_RESCHEDULE_REPLY: string
    INTERACTIVE_BUTTON_DO_NOT_REMEMBER_VEHICLE_KM: string
    INTERACTIVE_BUTTON_DO_NOT_OWN_VEHICLE_ANYMORE: string
    INTERACTIVE_BUTTON_ACCESS_TICKET: string
}

export type WhatsAppTemplateRequest = {
    group?: string
    broker: BrokerEnum
    language: Language
    channels: string[]
    template: WhatsAppTemplate
    interactive: boolean
    interactionType?: InteractionType
    defaultVariables?: DefaultVariable[]
}

export type DefaultVariable = {
    key: string
    value: string
}

export type WhatsAppTemplateResponse = {
    success: {
        channel: string
        status: number
    }[]
    error: {
        channel: string
        status: number
        stack?: string
        message?: string
    }[]
}

export type WhatsAppTemplateSyncRequest = {
    group?: string
    channel: string
}

export type Department = {
    group: string
    types: string[]
}

export type EditTemplateData = {
    id: string
    columns?: Pick<
        Partial<TemplateMessage>,
        | 'visible'
        | 'interactive'
        | 'interactionType'
        | 'canCreateRoom'
        | 'questionCompany'
        | 'ticketGenerationOptions'
        | 'label'
        | 'hasAdditionalCharge'
        | 'mediaUrl'
        | 'removed'
        | 'customTemplate'
        | 'department'
    >
    fields?: TemplatesFields[]
    department?: Department[]
}

export type EditTemplateRequest = {
    group?: string
    channel: string
    data: EditTemplateData
}

export type EditTemplateResponse = {
    template?: Partial<TemplateMessage>
    fields?: Partial<TemplateMessageDefaultValue>[]
}

export type DeleteTemplateRequest = {
    group?: string
    broker: BrokerEnum
    language: Language
    channel: string
    templates: { id: string; name: string }[]
}

export type TemplateDeletionResponse = {
    channel: string
    templates: { id: string; name: string; error?: ErrorTemplateDeletion }[]
}

export type UpdateTemplateStatusRequest = {
    templatesId: string[]
    status: WhatsAppTemplateStatus
}

export enum TemplatesInteractiveFilter {
    NO = 'NO',
    YES = 'YES',
    BOTH = 'BOTH'
}

export enum TemplatesVisibleFilter {
    NO = 'NO',
    YES = 'YES',
    BOTH = 'BOTH'
}

export enum TemplatesStatusFilter {
    ALL = 'ALL',
    AVAILABLE = 'AVAILABLE',
    INACTIVE = 'INACTIVE',
    PENDING_CONFIGURATION = 'PENDING_CONFIGURATION',
    PENDING_DELETION = 'PENDING_DELETION',
    WAITING_APPROVAL = 'WAITING_APPROVAL',
    REJECTED = 'REJECTED'
}

export type ChannelTemplatesRequestOptions = {
    page: number
    limit: number
    status?: TemplatesStatusFilter
    interactive?: TemplatesInteractiveFilter
    visible?: TemplatesVisibleFilter
    name?: string
    sync?: string
    interactionType?: InteractionType
    department?: Department[]
}

export type BrokerCreationResponse = {
    templates: saveTemplateRequest[]
    duplicatedTemplates: InfobipDuplicatedCreationResponse[] | GupshupDuplicatedCreationResponse[]
}

export type saveTemplateRequest = { template: Partial<TemplateMessage>; defaultVariables?: DefaultVariable[] }

export const buildTemplatePermissions = (broker: BrokerEnum): TemplatesChannelPermissions => {
    const permissions: TemplatesChannelPermissions = {
        allowCreation: false,
        allowDeletion: false,
        allowEdition: false
    }

    if (BrokerEnum.INFOBIP === broker) {
        permissions.allowCreation = true
        permissions.allowDeletion = true
    }

    if (BrokerEnum.GUPSHUP === broker) {
        permissions.allowCreation = true
        permissions.allowDeletion = true
    }

    return permissions
}

export type ChannelInteractiveTemplates = {
    channel: string
    templates: {
        id: string
        label: string
    }[]
}
