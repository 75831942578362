import { DateFns, lodash, Strings } from '@syonet/lang'
import { useApplicationStore } from './aplication-store'
import { useChatStore } from './feature/chat/chat-store'
import { ChatContactScope } from './feature/chat/chat-scope'
import MainTextProvider from './feature/main/texts'
import { ChatContactsRequest, ChatService, ChatTicketsRequest } from './feature/chat/Chat.service'
import { HostCommunication } from './host-communication'
import { capitalize } from './utils/views/capitalize'

const texts = MainTextProvider.get()
const chatService = ChatService.singleton()

export const loadChat = lodash.debounce(async function () {
    const appStore = useApplicationStore.getState()
    const chatStore = useChatStore.getState()
    const account = appStore.user!.domain
    const username = appStore.user!.username

    let tickets = appStore.tickets?.filter(({ attendant }) => !attendant)
    let preSaletickets = appStore.tickets?.filter(({ attendant }) => attendant)

    if (preSaletickets?.length) {
        preSaletickets = await chatService.handlePreSaleTickets({
            account,
            username,
            tickets: preSaletickets
        })

        tickets = [...tickets, ...preSaletickets]
    }

    const contactsRequest: ChatContactsRequest = {
        tickets,
        account,
        userName: username,
        selectedContactId: chatStore.selectedContact?.id,
        itemsPerPage: chatStore.itemsPerPage,
        pageIndex: chatStore.pageIndex,
        fullScreen: appStore.fullPage
    }

    const contactsResp = await chatService.fetchContacts(contactsRequest)

    const itemCount = contactsResp.contacts.length

    if (!chatStore && itemCount > 0) {
        contactsResp.selectedContact = contactsResp.contacts[0]
    }

    const selectedContact = chatStore.selectedContact ? chatStore.selectedContact : contactsResp.contacts[0]

    let onSelectFn: () => void = () => {}

    const notifications = appStore.notifications

    const customers: ChatContactScope[] = contactsResp.contacts.map((contact) => {
        contact.customer.name = contact.customer?.name || 'cliente não identificado'
        const id = contact.id
        const initials = computeInitials(contact.customer.name)
        const name = capitalize(contact.customer.name)
        const phone = Strings.formatPhoneV2(contact.customer.phone)
        const date = DateFns.format(contact.createdAt, texts.DATE_FORMAT)
        const time = DateFns.format(contact.createdAt, texts.TIME_FORMAT)
        const datetime = DateFns.format(contact.createdAt, texts.DATE_HH_MM_SS_FORMAT)
        const message = contact.message || texts.NO_MESSAGE
        const quantity = notifications
            ? notifications.filter((b) => b.phone === contact.customer.phone).reduce((a, b) => a + b.quantity, 0)
            : 0

        const header = { name, phone, initials }

        const customer = {
            id,
            initials,
            name,
            phone,
            date,
            time,
            datetime,
            ticketId: contact.ticket?.id || 0, // Sempre vai ter um ticket, não sei pq é opcional
            message,
            quantity,
            onSelect: () => {
                useChatStore.setState((state) => ({
                    selectedContact: contact,
                    contactsOpened: false,
                    header: { ...state.header, ...header },
                    whatsapp: { ...state.whatsapp, loading: true }
                }))
                loadCustomerTickets()
                configureWhatsapp()
            }
        }

        if (!chatStore.selectedContact && selectedContact.id === customer.id) {
            onSelectFn = customer.onSelect
        }

        return customer
    })

    const { itemsPerPage, currentPage, numOfReadMessages, numOfUnreadMessages } = contactsResp

    const total = (numOfReadMessages || 0) + (numOfUnreadMessages || 0)

    chatStore.updateContacts({
        itemCount: total,
        itemsPerPage,
        entries: customers,
        pageIndex: currentPage
    })

    onSelectFn()
}, 350)

export const configureWhatsapp = lodash.debounce(() => {
    const chatState = useChatStore.getState()
    const appState = useApplicationStore.getState()

    const {
        selectedContact,
        whatsapp: { language }
    } = chatState

    if (!selectedContact) {
        return
    }

    const { account, ticket, customer, user, channel } = selectedContact

    if (!account || !ticket?.id || !customer || !user.id || !channel) {
        return
    }

    const ticketInfo = appState.tickets.find((tic) => tic.id == ticket.id)
    let eventInfo: { group: string; type: string }
    if (ticketInfo) {
        const { group, type } = ticketInfo
        eventInfo = { group, type }
    }

    const companyId = ticketInfo?.companyId || null
    const room = toRoom(account, ticket.id)

    const hostCommunication = HostCommunication.singleton()
    hostCommunication.notifyCustomerChanged(room, ticket.id)

    useChatStore.setState((state) => ({
        whatsapp: {
            ...state.whatsapp,
            loading: false,
            companyId,
            companyPhone: channel,
            customerName: customer.name,
            customerPhone: customer.phone,
            userName: user.id,
            language,
            room,
            eventInfo
        }
    }))
}, 500)

function toRoom(account: string, ticket: number) {
    return `${account}-${ticket}`
}

const loadCustomerTickets = lodash.debounce(async () => {
    const appState = useApplicationStore.getState()
    const chatState = useChatStore.getState()

    const ticketsRequest: ChatTicketsRequest = {
        account: appState.user!.domain,
        tickets: appState.tickets,
        customerPhone: chatState.selectedContact?.customer.phone,
        userName: appState!.user!.username,
        pageIndex: 0,
        itemsPerPage: 100
    }

    const ticketsResp = await chatService.fetchTickets(ticketsRequest)

    const tickets = ticketsResp.tickets.map((entry) => ({
        id: entry.id,
        text: `${entry.group}/${entry.type} - ${entry.status}`
    }))

    useChatStore.setState((state) => ({ header: { ...state.header, tickets } }))
}, 500)

function computeInitials(name: string) {
    const parts = Strings.removeDiacritics(name).split(' ')
    const firstName = parts[0]
    const secondName = parts[1]
    if (firstName && secondName) {
        return `${firstName.charAt(0)}${secondName.charAt(0)}`.toUpperCase()
    } else if (firstName && firstName.length >= 2) {
        return `${firstName.charAt(0)}${firstName.charAt(1)}`.toUpperCase()
    } else {
        return '??'
    }
}
